<template>
  <div class="d-flex flex-column flex-root">
    <Loader v-if="showLoader" :logo="yediLogo"></Loader>

    <div
      id="kt_login"
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': state == 'signin',
        'login-signup-on': state == 'signup',
        'login-forgot-on': state == 'forgot'
      }"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="
          background-color: #ff3554;
          background-size: cover;
          background-position: center;
        "
        :style="'background-image: url(' + asideImagePath + ')'"
      >
        <div
          class="d-flex flex-column-auto flex-column pt-lg-40 md-pt-15 text-center"
        >
          <b-row class="p-0 m-0">
            <b-col
              md="12"
              class="vertical-center p-0 m-0"
              style="max-width: 600px"
            >
            </b-col>
          </b-row>
        </div>
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-7 mx-auto bg-white"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin" :class="{ 'd-none': hideAreas }">
            <form
              id="kt_login_signin_form"
              ref="loginForm"
              class="form"
              novalidate="novalidate"
              @submit="onSubmitLogin"
            >
              <div class="pb-13 pt-lg-0 pt-2 text-center">
                <h3
                  class="font-weight-bolder mb-7 font-size-h4 font-size-h1-lg"
                  style="color: #266f92"
                >
                  {{ $t("login.welcomeAt") }}
                </h3>
                <img style="max-width: 70%" :src="yediLogo" />
              </div>
              <div class="form-group">
                <label class="font-size-h6 font-weight-bolder text-dark">
                  {{ $t("login.mail") }}
                </label>
                <div>
                  <input
                    ref="email"
                    v-model="$v.form.email.$model"
                    class="form-control h-auto py-3 px-3"
                    type="text"
                    name="email"
                    :class="{
                      'is-invalid': $v.form.email.$error,
                      'is-valid': $v.form.email.$dirty && !$v.form.email.$error
                    }"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label
                    class="font-size-h6 font-weight-bolder text-dark pt-5"
                    >{{ $t("login.password") }}</label
                  >
                  <a
                    id="kt_login_forgot"
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    @click="showForm('forgot')"
                  >
                    {{ $t("login.passwordForgotten") }}
                  </a>
                </div>
                <div id="example-input-group-2" label-for="example-input-2">
                  <div class="input-group">
                    <input
                      ref="new_password"
                      v-model="$v.form.password.$model"
                      :type="[showPassword ? 'text' : 'password']"
                      class="form-control h-auto py-3 px-3"
                      :class="{
                        'is-invalid': $v.form.password.$error,
                        'is-valid':
                          $v.form.password.$dirty && !$v.form.password.$error
                      }"
                      name="new_password"
                    />
                    <div
                      class="input-group-append"
                      @click="showPassword = !showPassword"
                    >
                      <span class="input-group-text">
                        <i
                          class="fal icon-lg cursor-pointer"
                          :class="[showPassword ? 'fa-eye-slash' : 'fa-eye']"
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="!tooManyLoginAttempts" class="pb-lg-0 pb-5">
                <button
                  ref="kt_login_signin_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  {{ $t("login.login") }}
                </button>
              </div>
              <div v-else>
                <h6 class="text-danger">
                  {{ $t("login.tooManyLoginAttempts") }}
                </h6>
                <b-progress :max="waitSecondsMax" height="3rem">
                  <b-progress-bar :value="waitSeconds">
                    <span>
                      <h5>{{ waitSeconds }} {{ $t("general.seconds") }}</h5>
                    </span>
                  </b-progress-bar>
                </b-progress>
              </div>
            </form>
          </div>

          <!--end::Signin-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot" :class="{ 'd-none': hideAreas }">
            <!--begin::Form-->
            <form
              id="kt_login_forgot_form"
              ref="kt_login_forgot_form"
              class="form"
              novalidate="novalidate"
            >
              <div class="pb-5 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{ $t("login.passwordForgotten") }}
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  {{ $t("login.passwordResetDescription") }}
                </p>
              </div>
              <div v-if="!passwordReset.linkSent">
                <div class="form-group">
                  <input
                    v-model="$v.passwordReset.email.$model"
                    type="text"
                    class="form-control h-auto py-3 px-3"
                    :class="{
                      'is-invalid': $v.passwordReset.email.$error,
                      'is-valid':
                        $v.passwordReset.email.$dirty &&
                        !$v.passwordReset.email.$error
                    }"
                    :placeholder="$t('login.mail')"
                  />
                </div>
                <div class="form-group d-flex flex-wrap pb-lg-0">
                  <button
                    type="button"
                    class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                    @click="sendPasswordResetLink"
                  >
                    {{ $t("login.submit") }}
                  </button>
                  <button
                    id="kt_login_forgot_cancel"
                    type="button"
                    class="btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                    @click="showForm('signin')"
                  >
                    {{ $t("general.cancel") }}
                  </button>
                </div>
              </div>
              <div v-else>
                <p class="font-size-h4">
                  {{ $t("login.passwordResetMailSent") }}
                </p>
                <button
                  type="button"
                  class="btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  {{ $t("login.backToLogin") }}
                </button>
              </div>
            </form>
          </div>
          <div class="login-form login-reset" :class="{ 'd-none': !hideAreas }">
            <form id="kt_login_reset_form">
              <div class="pb-5 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  {{
                    $t("login.passwordResetFor", { email: $route.query.email })
                  }}
                </h3>
              </div>
              <div class="form-group">
                <input
                  v-model="$v.passwordReset.password.$model"
                  type="password"
                  class="form-control h-auto py-3 px-3 mb-3"
                  :class="{
                    'is-invalid': $v.passwordReset.password.$error,
                    'is-valid':
                      $v.passwordReset.password.$dirty &&
                      !$v.passwordReset.password.$error
                  }"
                  :placeholder="$t('login.password')"
                />
                <input
                  v-model="$v.passwordReset.passwordRepeat.$model"
                  type="password"
                  class="form-control h-auto py-3 px-3"
                  :class="{
                    'is-invalid': $v.passwordReset.passwordRepeat.$error,
                    'is-valid':
                      $v.passwordReset.passwordRepeat.$dirty &&
                      !$v.passwordReset.passwordRepeat.$error
                  }"
                  :placeholder="$t('login.passwordRepeat')"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  type="button"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                  @click="resetPassword"
                >
                  {{ $t("login.passwordChange") }}
                </button>
                <button
                  type="button"
                  class="btn btn-secondary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="$router.push('login')"
                >
                  {{ $t("general.cancel") }}
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a
            href="#"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
          >
            {{ $t("login.imprint") }}
          </a>
          <a
            href="#"
            class="text-primary ml-10 font-weight-bolder font-size-h5"
          >
            {{ $t("login.privacyPolicy") }}
          </a>
        </div>
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";

.swal-modal .swal2-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 1.2em;
  height: 1.2em;
  transition: color 0.1s ease-out;
  border: none;
  background: transparent;
  color: #cccccc;
  font-family: serif;
  font-size: 40px;
  cursor: pointer;
}

.swal-modal .swal2-close:hover {
  color: #f27474;
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

@media all and (max-width: 1263px) {
  #kt_login .login-aside {
    display: none !important;
  }
}
</style>

<script>
import Auth from "@/components/General/Auth/auth";
import KTUtil from "@/assets/js/components/util";
import Swal from "sweetalert2";
import $ from "jquery";
import Loader from "@/components/SYS/content/Loader.vue";
import { required, minLength, email, sameAs } from "vuelidate/lib/validators";
import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME
} from "@/core/services/store/htmlclass.module";

export default {
  name: "GeneralAuthIndex",
  data() {
    return {
      state: "signin",
      form: {
        workspace: "",
        email: "",
        password: ""
      },
      showPassword: false,
      tooManyLoginAttempts: false,
      waitSeconds: 0,
      waitSecondsMax: 0,
      interval: {},
      asideImages: [
        "login-aside-1.jpg",
        "login-aside-2.jpg",
        "login-aside-3.jpg",
        "login-aside-4.jpg",
        "login-aside-5.jpg",
        "login-aside-6.jpg"
      ],
      asideImagePath: "",
      passwordReset: {
        email: "",
        linkSent: false,
        password: "",
        passwordRepeat: ""
      },
      showLoader: false
    };
  },
  validations: {
    form: {
      email: { required, email },
      password: { required, minLength: minLength(8) }
    },
    passwordReset: {
      email: { required, email },
      password: { required, minLength: minLength(8) },
      passwordRepeat: {
        sameAsPassword: sameAs(function () {
          return this.passwordReset.password;
        })
      }
    }
  },
  computed: {
    yediLogo() {
      return process.env.BASE_URL + "media/logos/yedi_Logo.png";
    },
    yediSign() {
      return (
        process.env.BASE_URL + "media/logos/yedi_icon_weiß_transparent.png"
      );
    },
    hideAreas() {
      return this.$route.name === "passwordReset";
    }
  },
  mounted() {
    this.asideImage();
  },
  methods: {
    asideImage() {
      let image =
        this.asideImages[Math.floor(Math.random() * this.asideImages.length)];
      this.asideImagePath = process.env.BASE_URL + "media/login/" + image;
    },
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
      this.passwordReset.linkSent = false;
    },
    async onSubmitLogin(evt) {
      evt.preventDefault();

      this.$v.form.$touch();

      let authResponse = "";
      let self = this;

      // set spinner to submit button
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      let workspace = this.form.email.split("@");
      this.form.workspace = workspace[1];

      if (this.form.workspace === process.env.VUE_APP_ADMIN_WORKSPACE) {
        authResponse = await Auth.adminLoginCheck(this.form);
      } else {
        authResponse = await Auth.login(this.form);
      }

      submitButton.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );

      if (authResponse.success) {
        this.$router.push({ name: "dashboard" });
        return false;
      }

      let responseMessage = this.$t("login.loginDetailsWrong");

      if (typeof authResponse.data.userActiveStatus !== "undefined") {
        responseMessage = this.$t("login.accountDeactivated");
      }

      if (typeof authResponse.data.locked !== "undefined") {
        responseMessage = this.$t("login.accountLocked");
      }

      if (typeof authResponse.data.untrustedIP !== "undefined") {
        responseMessage = this.$t("login.unstrustedIP", {
          ip: authResponse.data.untrustedIP
        });
      }

      if (typeof authResponse.data.seconds !== "undefined") {
        responseMessage = this.$t("login.tooManyLoginAttempts");
        self.waitSeconds = authResponse.data.seconds;
        self.waitSecondsMax = authResponse.data.seconds;
        self.tooManyLoginAttempts = true;

        self.interval = setInterval(function () {
          self.waitSeconds -= 1;
          if (self.waitSeconds <= 0) {
            self.tooManyLoginAttempts = false;
            clearInterval(self.interval);
          }
        }, 1000);

        return false;
      }

      if (typeof authResponse.data.twoFaRequired !== "undefined") {
        const { value: formValues } = await Swal.fire({
          title: this.$t("login.twoFaRequiredTitle"),
          icon: "info",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCloseButton: true,
          width: "550px",
          html:
            "<p>" +
            this.$t("login.twoFaRequired") +
            "</p>" +
            '<div class="form-group"><input ref="twoFa" id="code" placeholder="' +
            this.$t("login.enterCode") +
            '" class="form-control mb-0 swal2-input">' +
            '<div class="invalid-feedback">' +
            this.$t("validation.required.code") +
            "</div></div>" +
            '<label class="checkbox"><input id="rememberDevice" type="checkbox"/><span class="mr-1"></span> ' +
            this.$t("login.saveDeviceAsTrusted") +
            "</label></span>",
          focusConfirm: false,
          preConfirm: () => {
            if (document.getElementById("code").value) {
              return [
                $("#code").val(),
                $("#rememberDevice").prop("checked") ? 1 : 0
              ];
            } else {
              $("#code").addClass("is-invalid");
              return false;
            }
          },
          didOpen(popup) {
            let $code = $(popup).find("#code");
            $code.focus();
            $code.on("keypress", function (e) {
              if (e.which === 13) {
                Swal.clickConfirm();
              }
            });
          }
        });

        if (formValues === undefined || formValues[0] === "") {
          return false;
        }

        this.form.twoFaCode = formValues[0];
        this.form.remember = formValues[1];

        this.loaderShow();
        let twoFaResponse = await Auth.twoFaCheck(this.form);

        if (twoFaResponse.success) {
          self.$router.push({ name: "dashboard" });
          return false;
        }

        this.loaderHide();

        Swal.fire({
          title: this.$t("general.caution"),
          text: this.$t("login.twoFaCodeWrong"),
          icon: "error",
          allowOutsideClick: false,
          allowEscapeKey: false,
          showCloseButton: true,
          width: "550px",
          showCancelButton: true,
          reverseButtons: true,
          cancelButtonText: this.$t("general.cancel"),
          showConfirmButton: true,
          confirmButtonText: this.$t("login.sendMeAnotherCode")
        }).then(function (res) {
          if (res.isDismissed) {
            return false;
          }
          self.form = {
            workspace: self.form.workspace,
            email: self.form.email,
            password: self.form.password
          };
          self.$refs.kt_login_signin_submit.click();
        });
        return false;
      }

      Swal.fire({
        title: this.$t("general.caution"),
        text: responseMessage,
        icon: "error"
      });
    },
    sendPasswordResetLink() {
      this.$v.passwordReset.email.$touch();
      if (this.$v.passwordReset.email.$invalid) return;

      this.loaderShow();

      const data = {
        email: this.passwordReset.email
      };
      Auth.sendPasswordResetLink(data)
        .then(() => {
          this.passwordReset.linkSent = true;
          this.loaderHide();
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.loaderHide();
        });
    },
    resetPassword() {
      this.$v.passwordReset.password.$touch();
      this.$v.passwordReset.passwordRepeat.$touch();
      if (
        this.$v.passwordReset.password.$invalid ||
        this.$v.passwordReset.passwordRepeat.$invalid
      )
        return;
      const data = {
        email: this.$route.query.email,
        token: this.$route.query.token,
        password: this.passwordReset.password,
        password_confirmation: this.passwordReset.passwordRepeat
      };
      this.loaderShow();
      Auth.resetPassword(data)
        .then(() => {
          this.$toast.fire({
            icon: "success",
            title: this.$t("login.passwordResetSuccess")
          });
          this.$router.push({ name: "login" });
          this.loaderHide();
        })
        .catch(error => {
          Swal.fire({
            icon: "error",
            title: this.$t("general.caution"),
            text: error.response?.data?.message
          });
          this.loaderHide();
        });
    },
    loaderShow() {
      this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");
      this.showLoader = true;
    },
    loaderHide() {
      this.showLoader = false;
      this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
    }
  },
  components: {
    Loader
  }
};
</script>
